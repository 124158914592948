<template>
  <!-- Footer -->
  <footer class="footer-style bg-gray-100 pt-200">
    <!-- Container -->
    <div class="container">
      <div class="footer-middle-area mt-30 pb-30 pt-60">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-wrapper mb-30">
              <h3 class="footer-title">About Us</h3>
              <div class="footer-text">
                <p>
                  The CreditCapital platform provides a factory that allows you,
                  the user, to deploy a Hedge Fund smart contract that can
                  safeguard your crypto assets.
                </p>
              </div>
              <div class="footer-icon" style="display: none">
                <a href="#"><i class="uil uil-facebook-f"></i></a>
                <a href="#"><i class="uil uil-twitter"></i></a>
                <a href="#"><i class="uil uil-instagram-alt"></i></a>
                <a href="#"><i class="uil uil-youtube"></i></a>
              </div>
            </div>
          </div>
          <!-- /col -->
          <!-- col -->
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-wrapper mb-30">
              <h3 class="footer-title">Platform</h3>
              <div class="footer-link">
                <ul>
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="reward">Rewards</router-link></li>
                  <li><router-link to="stake">Stake</router-link></li>
                  <li><router-link to="liquidity">Liquidity</router-link></li>
                  <li><router-link to="swap">Swap</router-link></li>
                </ul>
              </div>
              <div></div>
            </div>
          </div>
          <!-- /col -->
          <!-- col -->
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-wrapper mb-30" style="display: none">
              <h3 class="footer-title">Info</h3>
              <div class="footer-link">
                <ul>
                  <li><a href="#">About</a></li>
                  <li><a href="#">Conditions</a></li>
                  <li><a href="#">FAQ</a></li>
                  <li><a href="#">Documentation</a></li>
                  <li><a href="#">Terms of Use</a></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /col -->
          <!-- col -->
          <div class="col-xl-3 col-lg-3 col-md-6" style="display: none">
            <div class="footer-wrapper mb-30">
              <h3 class="footer-title">Subscribe</h3>
              <div class="subscribes-form">
                <form action="#">
                  <input placeholder="Enter email " type="email" />
                  <button class="btn theme-btn-1 width-100 mt-10">
                    <i class="lab la-telegram-plane me-2"></i>subscribe
                  </button>
                </form>
              </div>
              <div class="footer-info">
                <p>
                  Get the latest updates via email. Any time you may unsubscribe
                </p>
              </div>
            </div>
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <div class="footer-bottom-area pt-25 pb-25">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="copyright">
              <p>
                © Copyrights 2022 <a href="/index.html">CreditCapital.</a> All
                rights reserved.
              </p>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
    </div>
    <!-- /Container -->
  </footer>
  <!-- /Footer -->
</template>

<script setup lang="ts"></script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital@1&family=Orbitron&display=swap");
</style>
